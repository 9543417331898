import React from "react";
import "./App.css";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Service from "./components/Service";
import About from "./pages/about/About";
import Home from "./pages/home/Home";
import Products from "./pages/products/Products";
import Testimonials from "./pages/testimonials/Testimonials";

const App: React.FC = () => {
  return (
    <div className="App font-NotoSans">
      <Header />
      <Home />
      <About />
      <Products />
      <Testimonials />
      <Service />
      <Footer />
    </div>
  );
};

export default App;
