import React from "react";
import TestimonialCard from "../../components/cards/TestimonialCard";
import client1 from "../../assets/images/client1.jpg";
import client2 from "../../assets/images/client2.jpg";
import client3 from "../../assets/images/client3.jpg";
import "./Testimonials.css";

const testimonialDetails = [
  {
    name: "Mad. Margaret",
    imagePath: client1,
    rating: 4.2,
    comment: `Lorem ipsum dolor sit amet, consectetur 
    adipiscing elit. Cursus nibh mauris, nec turpis 
    orci lectus maecenas. Suspendisse sed 
    magna eget nibh in turpis. Consequat duis 
    diam lacus arcu. Faucibus venenatis felis id 
    augue sit cursus pellentesque enim arcu. 
    Elementum felis magna pretium in tincidunt. 
    Suspendisse sed magna eget nibh in turpis. 
    Consequat duis diam lacus arcu`,
    position: "Lead designer",
  },
  {
    name: "Kofi Boye",
    imagePath: client2,
    rating: 5.0,
    comment: `Lorem ipsum dolor sit amet, consectetur 
    adipiscing elit. Cursus nibh mauris, nec turpis 
    orci lectus maecenas. Suspendisse sed 
    magna eget nibh in turpis. Consequat duis 
    diam lacus arcu. Faucibus venenatis felis id 
    augue sit cursus pellentesque enim arcu. 
    Elementum felis magna pretium in tincidunt. 
    Suspendisse sed magna eget nibh in turpis. 
    Consequat duis diam lacus arcu`,
    position: "Trader, Makola",
  },
  {
    name: "Mary Boateng",
    imagePath: client3,
    rating: 4.5,
    comment: `Lorem ipsum dolor sit amet, consectetur 
    adipiscing elit. Cursus nibh mauris, nec turpis 
    orci lectus maecenas. Suspendisse sed 
    magna eget nibh in turpis. Consequat duis 
    diam lacus arcu. Faucibus venenatis felis id 
    augue sit cursus pellentesque enim arcu. 
    Elementum felis magna pretium in tincidunt. 
    Suspendisse sed magna eget nibh in turpis. 
    Consequat duis diam lacus arcu`,
    position: "CTO, Company",
  },
];

const Testimonials = () => {
  return (
    <section className=" testimonials px-2 py-10 lg:h-[70vh]">
      <h1 className="font-bold text-4xl md:text-6xl text-slate-700 text-center mb-8">
        What our clients say about us{" "}
      </h1>
      <div className="flex flex-col items-center lg:flex-row lg:justify-evenly">
        {testimonialDetails.map((detail, index) => (
          <TestimonialCard
            key={index}
            id={index}
            name={detail.name}
            descriptionText={detail.comment}
            position={detail.position}
            rating={detail.rating}
            imagePath={detail.imagePath}
          />
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
