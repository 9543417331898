import React from "react";
import { DynamicStar } from "react-dynamic-star";

let cardDetailProps = {
  id: 0,
  name: "",
  descriptionText: "",
  rating: 0,
  position: "",
  imagePath: "",
};

const TestimonialCard = (props: any) => {
  cardDetailProps = { ...cardDetailProps, ...props };

  return (
    <div className="w-full md:w-[50vw] lg:w-[24vw] h-[45vh] lg:h-[45vh] mb-5 px-5 py-10 rounded-2xl text-white bg-deepBlue">
      <div className="flex flex-row">
        <div className="w-[20%] object-contain mr-8">
          <img
            src={cardDetailProps.imagePath}
            alt="customer avatar"
            className=" rounded-full"
          />
        </div>
        <div className="flex flex-col justify-around items-start">
          <p className="font-bold text-lg">{cardDetailProps.name}</p>
          <div className="flex flex-row">
            <span className="mr-5">{cardDetailProps.position}</span>
            <DynamicStar
              width="14"
              height="14"
              rating={cardDetailProps.rating}
              outlined
            />
          </div>
        </div>
      </div>
      <p className="py-3 text-sm w-[full]">{cardDetailProps.descriptionText}</p>
    </div>
  );
};

export default TestimonialCard;
