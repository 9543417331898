import React from "react";
import "./Home.css";
import family from "../../assets/images/happy-family.png";

const Home: React.FC = () => {
  return (
    <section className=" h-[100vh]">
      <div className="home">
        <div className="frosted-effect-home flex flex-col items-center justify-center px-2 text-white space-y-2 md:space-y-3 lg:hidden text-center">
          <h1 className="font-bold text-4xl md:text-6xl">
            Secure your future today
          </h1>
          <h3 className="font-semibold text-xl md:text-2xl">
            We are digitizing pensions to help you secure your future
          </h3>
        </div>
      </div>
      <div className="hidden lg:flex  py-40 flex-row-reverse items-center justify-between px-20 h-full">
        <div className="w-[20vw] h-[65vh] border-2 border-deepBlue absolute"></div>
        <img
          src={family}
          alt="happy family"
          className="w-[40vw] z-[1] relative -left-[9.5rem] shadow-lg shadow-slate-400"
        />
        <div className="text flex flex-col justify-start items-start w-[35vw] space-y-3">
          <h1 className="font-bold text-4xl md:text-6xl text-deepBlue">
            Secure your future today
          </h1>
          <h3 className="font-semibold text-xl md:text-2xl text-gray-600">
            We are digitizing pensions to help you secure your future
          </h3>
        </div>
      </div>
    </section>
  );
};

export default Home;
