import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

const Service = () => {
  return (
    <section className="bg-deepBlue text-white h-[15vh] px-2 flex items-center justify-center border-b-2 border-b-white">
      <div className="flex items-center justify-center space-x-10 lg:space-x-20">
        <div>
          <h3 className="font-bold text-lg md:text-2xl lg:text-4xl">
            You need a service?
          </h3>
        </div>
        <div className="flex font-bold text-lg md:text-2xl lg:text-4xl space-x-3">
          <FontAwesomeIcon icon={faPhoneAlt} /> <h3>+233 550 363 94 34</h3>
        </div>
      </div>
    </section>
  );
};

export default Service;
