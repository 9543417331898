import React from "react";
import "./About.css";
import woman from "../../assets/images/woman.png";

const About: React.FC = () => {
  return (
    <section className="about-section h-[80vh]">
      <div className="about">
        <div className="frosted-effect flex flex-col items-center justify-center px-2 text-white space-y-2 md:space-y-3 lg:hidden text-center">
          <h1 className="font-bold text-4xl md:text-6xl">About Us</h1>
          <p className="font-semibold text-lg">
            We are changing the narrative around the future security of citizens
            especially women in frontier markets. We are creating an equal
            opportunity platform for the vulnerable to access financial services
            to secure their future. We preach freedom and independence for all
            our clients. Our goal is people should have a protected future.
            Access to pension benefits, investments and savings benefits. We are
            changing the future; be part of us.
          </p>
          <button className="mt-9 text-semibold bg-deepBlue rounded-lg px-6 py-2 text-white hover:bg-lightBlue hover:text-deepBlue self-center">
            Learn More
          </button>
        </div>
      </div>
      <div className="hidden lg:flex  py-40 flex-row items-center justify-between px-20 h-full">
        <div className="w-[20vw] h-[65vh] border-2 border-deepBlue absolute"></div>
        <img
          src={woman}
          alt="happy african market woman"
          className="w-[40vw] z-[1] relative left-[9.5rem] shadow-lg shadow-slate-400"
        />
        <div className="text flex flex-col justify-start items-start w-[35vw] space-y-3">
          <h1 className="font-bold text-2xl md:text-6xl text-deepBlue text-center">
            About Us
          </h1>
          <p className="font-normal text-gray-600 text-lg">
            We are changing the narrative around the future security of citizens
            especially women in frontier markets. We are creating an equal
            opportunity platform for the vulnerable to access financial services
            to secure their future. We preach freedom and independence for all
            our clients. Our goal is people should have a protected future.
            Access to pension benefits, investments and savings benefits. We are
            changing the future; be part of us.
          </p>
          <button className="mt-9 text-semibold bg-deepBlue rounded-lg px-6 py-2 text-white hover:bg-lightBlue hover:text-deepBlue self-center">
            Learn More
          </button>
        </div>
      </div>
    </section>
  );
};

export default About;
