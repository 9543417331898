import React from "react";
import logo from "../../assets/images/yesika-transparent.png";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useScrollPosition } from "../../hooks/useScrollPosition";

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

const Header: React.FC = () => {
  const scrollPosition = useScrollPosition();
  return (
    <nav
      className={classNames(
        scrollPosition > 0 ? "shadow-2xl" : "shadow-none",
        "transition-shadow fixed z-50 top-0 px-2 w-full h-[12vh] flex flex-row items-center justify-between bg-white"
      )}
    >
      <div className="w-[30vw] md:w-[20vw] lg:w-[10vw]">
        <img src={logo} alt="Yesika Logo" className="object-contain" />
      </div>

      <div className="menu">
        <FontAwesomeIcon
          icon={faBars}
          className="text-2xl md:text-4xl lg:hidden text-deepBlue"
        />
      </div>

      <div className="menu-items hidden lg:flex w-[60vw] justify-center">
        <ul className="flex flex-row  text-xl space-x-20 text-deepBlue font-semibold">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/">Services</a>
          </li>
          <li>
            <a href="/">About us</a>
          </li>
          <li>
            <a href="/">Contact</a>
          </li>
        </ul>
      </div>

      <div className="buttons hidden  lg:flex justify-end space-x-5">
        <button className="text-semibold bg-deepBlue rounded-lg px-6 py-2 text-white hover:text-lg">
          Get started
        </button>
        <button className="text-semibold bg-lightBlue rounded-lg px-6 py-2 text-white hover:text-lg">
          Login
        </button>
      </div>
    </nav>
  );
};

export default Header;
