import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoins,
  faChartLine,
  faCreditCard,
  faPiggyBank,
} from "@fortawesome/free-solid-svg-icons";
import pensions from "../../assets/images/pensions.png";
import savings from "../../assets/images/savings.png";
import investment from "../../assets/images/investment.png";
import payment from "../../assets/images/payment.png";

const Products = () => {
  return (
    <section className="pb-8 lg:pb-0 lg:h-[75vh]">
      <div className="heading flex flex-col justify-center items-center">
        <h1 className="py-8 font-NotoSans text-deepBlue font-bold text-4xl md:text-6xl">
          Our Products
        </h1>

        <div className="flex flex-col md:grid grid-cols-2 gap-3 md:gap-x-8 md-gap-y-4 lg:hidden">
          <div className="outline outline-2 outline-deepBlue text-white bg-deepBlue rounded-md w-[90vw] h-[25vh] md:w-[30vw] lg:w-[20vw] lg:h-[20vh] mb-5 lg:mr-5 flex flex-col items-center justify-center">
            <FontAwesomeIcon icon={faCoins} className="text-6xl mb-6" />
            <div className="text-xl font-NotoSans px-4 pb-5">
              <h4>Our flagship product: Pensions</h4>
            </div>
          </div>
          <div className="outline outline-2 outline-deepBlue text-deepBlue bg-white rounded-md w-[90vw] h-[25vh] md:w-[30vw] lg:w-[20vw] lg:h-[20vh] mb-5 lg:mr-5 flex flex-col items-center justify-center">
            <FontAwesomeIcon icon={faPiggyBank} className="text-6xl mb-6" />
            <div className="text-xl font-NotoSans px-4 pb-5">
              <h4>Savings</h4>
            </div>
          </div>
          <div className="outline outline-2 outline-deepBlue text-white bg-deepBlue rounded-md w-[90vw] h-[25vh] md:w-[30vw] lg:w-[20vw] lg:h-[20vh] mb-5 lg:mr-5 flex flex-col items-center justify-center">
            <FontAwesomeIcon icon={faChartLine} className="text-6xl mb-6" />
            <div className="text-xl font-NotoSans px-4 pb-5">
              <h4>Investment</h4>
            </div>
          </div>
          <div className="outline outline-2 outline-deepBlue text-deepBlue bg-white rounded-md w-[90vw] h-[25vh] md:w-[30vw] lg:w-[20vw] lg:h-[20vh] mb-5 lg:mr-5 flex flex-col items-center justify-center">
            <FontAwesomeIcon icon={faCreditCard} className="text-6xl mb-6" />
            <div className="text-xl font-NotoSans px-4 pb-5">
              <h4>Payments</h4>
            </div>
          </div>
        </div>

        <div className="hidden lg:grid grid-cols-4 gap-x-10">
          <div className="card h-[45vh] w-[16vw] rounded-2xl bg-deepBlue border-2 border-deepBlue text-white flex flex-col items-center justify-between">
            <div className="image h-[10vh] rounded-t-2xl ">
              <img
                src={pensions}
                alt="Pensions"
                className="object-contain rounded-t-2xl"
              />
            </div>

            <div className="h-[15vh]">
              <h3 className="text-lg font-semibold">Our flagship product:</h3>
              <h2 className="text-2xl font-semibold">Pensions</h2>
            </div>
          </div>

          <div className="card h-[45vh] w-[16vw] rounded-2xl bg-deepBlue border-2 border-deepBlue text-white flex flex-col items-center justify-between">
            <div className="image h-[10vh] rounded-t-2xl ">
              <img
                src={savings}
                alt="Savings"
                className="object-contain rounded-t-2xl"
              />
            </div>

            <div className="h-[15vh]">
              <h3 className="text-xl font-semibold">Savings</h3>
            </div>
          </div>

          <div className="card h-[45vh] w-[16vw] rounded-2xl bg-deepBlue border-2 border-deepBlue text-white flex flex-col items-center justify-between">
            <div className="image h-[10vh] rounded-t-2xl ">
              <img
                src={investment}
                alt="Investment"
                className="object-contain rounded-t-2xl"
              />
            </div>

            <div className="h-[15vh]">
              <h3 className="text-xl font-semibold">Investment</h3>
            </div>
          </div>

          <div className="card h-[45vh] w-[16vw] rounded-2xl bg-deepBlue border-2 border-deepBlue text-white flex flex-col items-center justify-between">
            <div className="image h-[10vh] rounded-t-2xl ">
              <img
                src={payment}
                alt="Payment"
                className="object-contain rounded-t-2xl"
              />
            </div>

            <div className="h-[15vh]">
              <h3 className="text-xl font-semibold">Payments</h3>
            </div>
          </div>
        </div>

        <button className="mt-9 text-semibold bg-deepBlue rounded-lg px-6 py-2 text-white hover:bg-lightBlue hover:text-deepBlue self-center">
          Learn More
        </button>
      </div>
    </section>
  );
};

export default Products;
